import React from "react";
import logo from "../images/PNG-Win Clinic Plus Logo making.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import {
  Box,
  Collapse,
  Flex,
  Icon,
  Image,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { colours } from "../utils/colours";

const Header = ({
  scrollToSection,
  home,
  services,
  about,
  doctors,
  testimonials,
  appointment,
}) => {
  const navLinks = [
    {
      text: "Home",
      link: home,
    },
    {
      text: "Services",
      link: services,
    },
    {
      text: "About",
      link: about,
    },
    {
      text: "Doctors",
      link: doctors,
    },
    // {
    //   text: "Testimonials",
    //   link: testimonials,
    // },
    {
      text: "Appointment",
      link: appointment,
    },
  ];
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box
      position="sticky"
      bg={colours.light}
      minW="100%"
      zIndex={1000}
      top={0}
      boxShadow="sm"
    >
      <Flex
        px={{ lg: 20, md: 8, sm: 12, base: 4 }}
        w="100vw"
        maxW="1440px"
        mx="auto"
        alignItems="center"
        h={{ lg: "100px", base: "80px" }}
        justifyContent="space-between"
        pt={{ lg: 0, base: "18px" }}
        pb={{ lg: 0, base: "18px" }}
        bg={{ lg: "white" }}
      >
        <Image
          src={logo}
          width={{ lg: "205px", base: "140px" }}
          height={{ lg: "85px", base: "60px" }}
          objectFit="cover"
        />
        <Spacer />
        <Flex
          display={{ lg: "flex", base: "none" }}
          fontSize={{ xl: "12px", lg: "10px" }}
          fontWeight={500}
          letterSpacing={{ xl: 2, lg: 1.2 }}
          alignItems="center"
          justifyContent="center"
          h="100%"
          gap={{ xl: 6, lg: 4 }}
          textTransform="uppercase"
          color="black"
        >
          {navLinks.map((navLink) => (
            <Box
              key={navLink.text}
              h="100%"
              px={{ xl: 3, lg: 1.5 }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              _hover={{ backgroundColor: "#F2F2F2" }}
              cursor="pointer"
              transition="all"
              transitionDuration="0.3s"
              onClick={() => scrollToSection(navLink.link)}
            >
              {navLink.text}
            </Box>
          ))}
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          display={{ lg: "none", base: "flex" }}
        >
          {isOpen ? (
            <Icon fontSize="28px" onClick={onToggle} color={colours.blue}>
              <AiOutlineClose />
            </Icon>
          ) : (
            <Icon fontSize="28px" onClick={onToggle} color={colours.blue}>
              <AiOutlineMenu />
            </Icon>
          )}
        </Flex>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <VStack
          bg={colours.light}
          p={2}
          display={{ lg: "none" }}
          w="100vw"
          maxW="100%"
          pos="absolute"
        >
          {navLinks.map((navLink) => (
            <Flex key={navLink.text} height="14" px={8} alignItems="center">
              <Box
                onClick={() => {
                  scrollToSection(navLink.link);
                }}
                fontSize="16px"
              >
                {navLink.text}
              </Box>
            </Flex>
          ))}
        </VStack>
      </Collapse>
    </Box>
  );
};

export default Header;
