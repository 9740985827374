import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "../components/Button";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import VacationRecord from "../components/VacationRecord";
import { backendURL } from "../utils/constants";
import { token } from "../utils/token";

const EditPage = () => {
  const navigate = useNavigate();

  const params = useParams();
  const [res, setRes] = useState({});
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [allergy, setAllergy] = useState("");
  const [gender, setGender] = useState("");
  const [NRC, setNRC] = useState("");
  const [vaccine, setVaccine] = useState("");

  const fetchPatientData = async () => {
    const { data } = await axios.get(`${backendURL}patients/${params.id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setRes(data);
    setName(data.name);
    setDob(data.dob);
    setAddress(data.address);
    setAllergy(data.allergy);
    setGender(data.gender);
    setNRC(data.NRC);
    setVaccine(data.vaccine);
  };

  const vaccineOnChangeHandler = (i, key, value) => {
    const newVaccine = [...vaccine];

    newVaccine[i][key] = value;

    setVaccine(newVaccine);
  };

  async function editHandler() {
    if (name && dob && address && allergy && gender && NRC && vaccine) {
      await axios.put(
        `${backendURL}patients/edit/${params.id}`,
        {
          name,
          dob,
          address,
          allergy,
          gender,
          NRC,
          vaccine,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      navigate(`/admin-user-panel/patient/list`);
    }
  }

  useEffect(() => {
    fetchPatientData();
  }, []);

  return (
    <Flex flexDir="column" minH="100vh">
      <Navbar />
      <Box p={7} py={10}>
        <Flex gridGap="15px" my="10px">
          <FormControl>
            <FormLabel as="legend">Name</FormLabel>
            <Input
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Date of birth</FormLabel>
            <Input
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Allergy</FormLabel>
            <Input
              placeholder="Allergy"
              value={allergy}
              onChange={(e) => setAllergy(e.target.value)}
            />
          </FormControl>
        </Flex>
        <FormControl>
          <FormLabel as="legend">Gender</FormLabel>
          <RadioGroup
            defaultValue="Itachi"
            value={gender}
            onChange={(e) => setGender(e)}
          >
            <HStack spacing="24px">
              <Radio value="Male">Male</Radio>
              <Radio value="Female">Female</Radio>
              <Radio value="Other">Other</Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <Flex gridGap="15px" my="10px">
          <FormControl>
            <FormLabel as="legend">NRC/Passport</FormLabel>
            <Input
              placeholder="NRC/Passport"
              value={NRC}
              onChange={(e) => setNRC(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Address</FormLabel>
            <Input
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormControl>
        </Flex>
        {vaccine.length > 0 &&
          vaccine.map((data, i) => (
            <Flex flexDir="column">
              <Flex gridGap="15px" my="10px">
                <FormControl>
                  <FormLabel as="legend">Vaccine Timing</FormLabel>
                  <Input
                    placeholder="Vaccine Timing"
                    value={vaccine[i].vaccineTiming}
                    onChange={(e) =>
                      vaccineOnChangeHandler(i, "vaccineTiming", e.target.value)
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel as="legend">Type of vaccine</FormLabel>
                  <Input
                    placeholder="Type of vaccine"
                    value={vaccine[i].type}
                    onChange={(e) =>
                      vaccineOnChangeHandler(i, "type", e.target.value)
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel as="legend">Batch No</FormLabel>
                  <Input
                    placeholder="Batch No"
                    value={vaccine[i].batch}
                    onChange={(e) =>
                      vaccineOnChangeHandler(i, "batch", e.target.value)
                    }
                  />
                </FormControl>
              </Flex>
              <Flex gridGap="15px" my="10px">
                <FormControl>
                  <FormLabel as="legend">Expiry Date</FormLabel>
                  <Input
                    placeholder="Expiry Date"
                    type="date"
                    value={vaccine[i].exp}
                    onChange={(e) =>
                      vaccineOnChangeHandler(i, "exp", e.target.value)
                    }
                    // onChange={(e) => setExp(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel as="legend">Date</FormLabel>
                  <Input
                    type="date"
                    value={vaccine[i].date}
                    onChange={(e) =>
                      vaccineOnChangeHandler(i, "date", e.target.value)
                    }
                    // onChange={(e) => setDate(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel as="legend">
                    Healthcare Professional/ Clinic Site
                  </FormLabel>
                  <Select
                    value={vaccine[i].Professional}
                    onChange={(e) =>
                      vaccineOnChangeHandler(i, "Professional", e.target.value)
                    }
                    // onChange={(e) => setProfessional(e.target.value)}
                    placeholder="Select Healthcare Professional/ Clinic Site"
                  >
                    <option> Dr. Thet Nyi Nyi Lwin M.B.,B.S (Ygn)</option>
                    <option> Dr. Hnin Aye Hlaing M.B.,B.S (Ygn)</option>
                    <option> Dr. Aung Phone M.B.,B.S (Ygn)</option>
                    <option> Dr. Aung Myat M.B.,B.S (Ygn)</option>
                    <option> Dr. Myo Theint Theint Thu M.B.,B.S (Mgy)</option>
                  </Select>
                </FormControl>
              </Flex>
            </Flex>
          ))}

        <Flex justifyContent="flex-end">
          <ButtonComponent text="Edit" size="md" onClick={editHandler} />
        </Flex>
      </Box>
      <Footer />
    </Flex>
  );
};

export default EditPage;
