import React from "react";
import About from "../components/About";
import AppointmentForm from "../components/AppointmentForm";
import DoctorsList from "../components/DoctorsList";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import HomePageFooter from "../components/HomePageFooter";
import ServiceSection from "../components/ServiceSection";
import Testimonials from "../components/Testimonials";

const HomePage = () => {
  const home = React.createRef();
  const services = React.createRef();
  const about = React.createRef();
  const doctors = React.createRef();
  const testimonials = React.createRef();
  const appointment = React.createRef();

  function scrollToSection(elementRef) {
    elementRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  }

  return (
    <>
      <Header
        scrollToSection={scrollToSection}
        home={home}
        services={services}
        about={about}
        doctors={doctors}
        testimonials={testimonials}
        appointment={appointment}
      />
      <HeroSection
        scrollToSection={scrollToSection}
        home={home}
        services={services}
        doctors={doctors}
      />
      <ServiceSection services={services} />
      <About about={about} />
      <DoctorsList doctors={doctors} />
      {/* <Testimonials testimonials={testimonials} /> */}
      <AppointmentForm appointment={appointment} />
      <HomePageFooter
        scrollToSection={scrollToSection}
        home={home}
        services={services}
        about={about}
        doctors={doctors}
        testimonials={testimonials}
        appointment={appointment}
      />
    </>
  );
};

export default HomePage;
