import { useState } from "react";
import { Image, Box, VStack, Text } from "@chakra-ui/react";
import logo from "../images/letterhead top header (1).jpg";
import { colours } from "../utils/colours";
import React from "react";
import ButtonComponent from "../components/Button";
import InputComponent from "../components/Input";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { backendURL } from "../utils/constants";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = async () => {
    const { data } = await axios.post(`${backendURL}admins/login`, {
      email,
      password,
    });

    localStorage.setItem("userData", JSON.stringify(data.data));
    localStorage.setItem("winToken", data.accessToken);
    navigate("/admin-user-panel/patient/list");
  };

  return (
    <Box width="100vw" height="100vh" bgColor={colours.light} display="grid">
      <Box placeSelf="center" position="relative">
        <VStack marginY={4} spacing={8}>
          <Box>
            <Image
              src={logo}
              objectFit="cover"
              alt="MK Movie Admin Panel"
              htmlHeight="120px"
              htmlWidth="490px"
            />
          </Box>
          <Box
            width="470px"
            height="565px"
            bgColor={colours.main}
            borderRadius="20px"
          >
            <VStack spacing={8} paddingX={12}>
              <Text
                color={colours.light}
                paddingY={20}
                fontSize="3xl"
                fontWeight="medium"
                letterSpacing="1px"
              >
                Login
              </Text>
              <InputComponent
                type="text"
                placeholder="Enter username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputComponent
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Box paddingY={8}>
                <ButtonComponent text="Login" onClick={() => submitHandler()} />
              </Box>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

export default Login;
