import { Text } from "@chakra-ui/react";
import React from "react";
import { colours } from "../utils/colours";

function Title({ text, fontSize, fontWeight, colour }) {
  return (
    <Text
      color={colour || colours.textColour}
      fontSize={fontSize || "20px"}
      fontWeight={fontWeight || 500}
      letterSpacing="1px"
    >
      {text}
    </Text>
  );
}

export default Title;
