import { Box, Flex, Image, Text, Heading, Button } from "@chakra-ui/react";
import bannerImage from "../images/doctor-banner-image.png";
import { colours } from "../utils/colours";
import React from "react";

const HeroSection = ({ scrollToSection, home, services, doctors }) => {
  return (
    <Box bg={colours.blue} w="100vw" maxW="100%" ref={home}>
      <Flex
        maxW="1440px"
        mx="auto"
        px={{ "2xl": 0, md: 16, sm: 6, base: 4 }}
        alignItems="center"
      >
        <Box
          w={{ lg: "45%", base: "100%" }}
          px={{ lg: 10, md: 8, sm: 12, base: 4 }}
          pt={{ xl: 0, lg: 8, base: 16 }}
          color={colours.light}
          height="fit-content"
        >
          <Box mb={{ xl: 4, lg: 2, base: 4 }}>
            <Text
              fontSize={{ xl: "14px", lg: "12px", base: "10px" }}
              textTransform="uppercase"
              mb={2}
              letterSpacing={1.2}
            >
              We work with
            </Text>
            <Heading
              fontSize={{ xl: "22px", lg: "18px", sm: "16px", base: "16px" }}
              fontWeight="regular"
              letterSpacing={2}
              lineHeight={1.5}
            >
              Our Love and Passion towards
            </Heading>
            <Heading
              fontSize={{ xl: "22px", lg: "18px", sm: "16px", base: "16px" }}
              fontWeight="regular"
              mt="10px"
              // letterSpacing={2}
              lineHeight={1.5}
            >
              Joy and Healthy state of our Patient and families.
            </Heading>
          </Box>
          <Text
            fontSize={{ xl: "14px", lg: "12px", sm: "10px", base: "10px" }}
            width={{ xl: "480px", sm: "90%", base: "100%" }}
            letterSpacing={{ xl: 1.2, lg: 1, base: 1.2 }}
            mb={{ xl: 6, lg: 4, base: 6 }}
          >
            Our objective is to have the best health care assessible at a
            neighbour distance. A health care that cures the cause, not patching
            the symptom, with an academic consultation with experts.
          </Text>
          <Flex
            width={{ xl: "360px", md: "320px", base: "280px" }}
            justify="space-between"
            gap={4}
            mb={{ lg: 8, base: 16 }}
          >
            <Button
              variant="solid"
              bg={colours.light}
              textColor={colours.dark}
              _focus={{ outline: "none" }}
              _hover={{ backgroundColor: "#F2F2F2" }}
              type="button"
              fontSize="14px"
              fontWeight="bold"
              py={6}
              px={10}
              onClick={() => scrollToSection(services)}
            >
              Our Services
            </Button>
            <Button
              variant="solid"
              bg={colours.light}
              textColor={colours.dark}
              _focus={{ outline: "none" }}
              _hover={{ backgroundColor: "#F2F2F2" }}
              type="button"
              fontSize="14px"
              fontWeight="bold"
              py={6}
              px={10}
              onClick={() => scrollToSection(doctors)}
            >
              Meet Our Doctors
            </Button>
          </Flex>
        </Box>
        <Box w="55%" display={{ lg: "block", base: "none" }}>
          <Image
            mt={8}
            src={bannerImage}
            alt="Hero Section Banner Image"
            width="full"
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default HeroSection;
