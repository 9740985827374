import {
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  TableCaption,
  TableContainer,
  Text,
  Thead,
  Th,
} from "@chakra-ui/react";
import QRCode from "qrcode";

import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { frontendURL } from "../utils/constants";
import ButtonComponent from "./Button";
import VaccineRecordCard from "./VaccineRecordCard";
import { token } from "../utils/token";

const VacationRecord = ({ data }) => {
  const [src, setSrc] = useState("");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const generateQR = async (text) => {
    try {
      setSrc(await QRCode.toDataURL(text));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    generateQR(`${frontendURL}patient/${data._id}`);
  });

  const componentRef = useRef();
  return (
    <Flex
      flexDir="column"
      mt="30px"
      mx={{ xl: "100px", lg: "100px", md: "50px", sm: "10px", base: "10px" }}
    >
      <Text ml="20px" fontWeight="extrabold" fontSize="30px" color="#797979">
        Vaccination Record
      </Text>
      <Flex display="none">
        <Flex ref={componentRef} flexDir="column">
          {data && (
            <VaccineRecordCard
              qr={src}
              data={{
                name: data.name,
                dob: data.dob,
                address: data.address,
                allergy: data.allergy,
                gender: data.gender,
                NRC: data.NRC,
                vaccine: data.vaccine,
              }}
            />
          )}
        </Flex>
      </Flex>
      {token && (
        <Flex ml="20px" my="10px">
          <ButtonComponent
            text="Print Certificate"
            size="sm"
            onClick={handlePrint}
          />
        </Flex>
      )}

      <TableContainer
        w={{ xl: "70%", lg: "70", md: "90%" }}
        border="1px solid #111"
        padding="10px"
        m="10px"
        borderRadius="10px"
      >
        <Table size="sm" variant="striped" colorScheme="gray">
          <TableCaption>Personal Information of a patient</TableCaption>
          <Tbody>
            <Tr>
              <Td fontWeight="extrabold" color="#797979">
                Name
              </Td>
              <Td>{data.name}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="extrabold" color="#797979">
                Gender
              </Td>
              <Td>{data.gender}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="extrabold" color="#797979">
                Date of birth
              </Td>
              <Td>{data.dob}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="extrabold" color="#797979">
                NRC/Passport
              </Td>
              <Td>{data.NRC}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="extrabold" color="#797979">
                Allergy
              </Td>
              <Td>{data.allergy}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="extrabold" color="#797979">
                Address
              </Td>
              <Td>{data.address}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <TableContainer
        border="1px solid #111"
        padding="10px"
        m="10px"
        borderRadius="10px"
      >
        <Table size="sm" variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Vaccine Timing</Th>
              <Th>Type</Th>
              <Th>Batch No</Th>
              <Th>Exp</Th>
              <Th>Healthcare Professional</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.vaccine?.map((item) => (
              <Tr>
                <Td>{item.date}</Td>
                <Td>{item.vaccineTiming}</Td>
                <Td>{item.type}</Td>
                <Td>{item.batch}</Td>
                <Td>{item.exp}</Td>
                <Td>{item.Professional}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default VacationRecord;
