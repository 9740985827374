import { Flex } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import VacationRecord from "../components/VacationRecord";
import { backendURL } from "../utils/constants";
import { token } from "../utils/token";

const VacationRecordPage = () => {
  const params = useParams();
  const [res, setRes] = useState({});

  const fetchPatientData = async () => {
    const { data } = await axios.get(
      `${backendURL}patients/${params.patientId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setRes(data);
  };

  useEffect(() => {
    fetchPatientData();
  }, []);

  return (
    <Flex flexDir="column" minH="100vh">
      <Navbar />
      <VacationRecord data={res} />
      <Footer />
    </Flex>
  );
};

export default VacationRecordPage;
