import IconButtonComponent from "../components/IconButton";
import { BiPlus, BiTrashAlt, BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spacer,
  Flex,
  //   useToast,
} from "@chakra-ui/react";
import ButtonComponent from "../components/Button";
import { colours } from "../utils/colours";
import Title from "../components/Title";
// import Paginator from "../Paginator/Paginator";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { backendURL } from "../utils/constants";
import Paginator from "../components/Paginator";
import { token } from "../utils/token";

// const data = [
//   {
//     name: "Heee",
//     gender: "male",
//     dob: "13/6/2003",
//     NRC: "14/MaAhNa(N)243630",
//   },
//   {
//     name: "Heee",
//     gender: "male",
//     dob: "13/6/2003",
//     NRC: "14/MaAhNa(N)243630",
//   },
//   {
//     name: "Heee",
//     gender: "male",
//     dob: "13/6/2003",
//     NRC: "14/MaAhNa(N)243630",
//   },
//   {
//     name: "Heee",
//     gender: "male",
//     dob: "13/6/2003",
//     NRC: "14/MaAhNa(N)243630",
//   },
//   {
//     name: "Heee",
//     gender: "male",
//     dob: "13/6/2003",
//     NRC: "14/MaAhNa(N)243630",
//   },
//   {
//     name: "Heee",
//     gender: "male",
//     dob: "13/6/2003",
//     NRC: "14/MaAhNa(N)243630",
//   },
//   {
//     name: "Heee",
//     gender: "male",
//     dob: "13/6/2003",
//     NRC: "14/MaAhNa(N)243630",
//   },
//   {
//     name: "Heee",
//     gender: "male",
//     dob: "13/6/2003",
//     NRC: "14/MaAhNa(N)243630",
//   },
//   {
//     name: "Heee",
//     gender: "male",
//     dob: "13/6/2003",
//     NRC: "14/MaAhNa(N)243630",
//   },
// ];

const TableRow = ({ data, index, setIsChange, isChange }) => {
  const navigate = useNavigate();

  const deleteRecord = async () => {
    await axios.delete(`${backendURL}patients/${data._id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    setIsChange(!isChange);
  };

  return (
    <Tr>
      <Td borderColor="black" py="20px">
        {index}.
      </Td>
      <Td borderColor="black" whiteSpace="pre-wrap">
        {data?.name}
      </Td>
      <Td borderColor="black">{data?.gender}</Td>
      <Td borderColor="black">{data?.dob}</Td>
      <Td borderColor="black"> {data?.NRC}</Td>

      <Td borderColor="black">
        <IconButtonComponent
          mr="10px"
          icon={<BiPlus />}
          hover_text_colour={colours.secondary}
          onClick={() =>
            navigate(`/admin-user-panel/patient/list/edit/${data?._id}`)
          }
        />
        <IconButtonComponent
          mr="10px"
          icon={<BiEdit />}
          hover_text_colour={colours.secondary}
          onClick={() =>
            navigate(`/admin-user-panel/patient/edit/${data?._id}`)
          }
        />
        <IconButtonComponent
          mr="10px"
          icon={<BsEyeFill />}
          hover_text_colour={colours.secondary}
          onClick={() => navigate(`/patient/${data?._id}`)}
        />
        <IconButtonComponent
          hover_text_colour="red"
          icon={<BiTrashAlt />}
          onClick={deleteRecord}
        />
      </Td>
    </Tr>
  );
};

const PatientList = () => {
  const [page, setPage] = useState(1);
  const [res, setRes] = useState([]);
  const currentPath = useLocation().pathname;
  const [isChange, setIsChange] = useState(true);

  const setPatientList = async () => {
    const { data } = await axios.get(`${backendURL}patients?page=${page}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    setRes(data);
  };

  useEffect(() => {
    setPatientList();
  }, [page, isChange]);

  return (
    <Flex flexDir="column" bg="#fff" minH="100vh">
      <Navbar />
      <Box pt={4} px={6} display="flex">
        <Title text={`Total Patients - ${res?.total || 0}`} />
        <Spacer />
        <Link to={`${currentPath}/create`}>
          <ButtonComponent text={`Create`} size="md" />
        </Link>
      </Box>
      <Box py={4} px={6}>
        <TableContainer
          px="0"
          color={colours.textColour}
          borderBottom="transparent"
        >
          <Table variant="simple" px="0" border="1px solid black">
            <Thead h="60px">
              <Tr borderBottom="transparent">
                <Th borderColor="black" color={colours.textColour} width="5%">
                  No
                </Th>
                <Th borderColor="black" color={colours.textColour} width="27%">
                  Name
                </Th>
                <Th borderColor="black" color={colours.textColour} width="18%">
                  Gender
                </Th>
                <Th borderColor="black" color={colours.textColour} width="10%">
                  DOB
                </Th>
                <Th borderColor="black" color={colours.textColour} width="10%">
                  NRC
                </Th>
                <Th borderColor="black" color={colours.textColour} width="10%">
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {res.result?.map((item, index) => (
                <TableRow
                  data={item}
                  key={item._id}
                  index={index + 1}
                  setIsChange={setIsChange}
                  isChange={isChange}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Paginator
          currentPage={page}
          totalPages={res?.pages}
          setPage={setPage}
        />
      </Box>
      <Footer />
    </Flex>
  );
};
export default PatientList;
