import React from "react";
import { IconButton } from "@chakra-ui/react";
import { colours } from "../utils/colours";

const IconButtonComponent = ({
  icon,
  isRound,
  hover_text_colour,
  bgcolour = colours.background,
  mr,
  onClick,
}) => {
  return (
    <IconButton
      variant="solid"
      bg={bgcolour}
      textColor={colours.light}
      fontSize="20px"
      _hover={{ textColor: `${hover_text_colour}` }}
      size="sm"
      mr={mr}
      icon={icon}
      isRound={isRound}
      onClick={onClick}
    />
  );
};

export default IconButtonComponent;
