import { Box, Image, Flex, Text, VStack, HStack, Link } from "@chakra-ui/react";
import React from "react";
import { colours } from "../utils/colours";
import { AiFillPhone, AiFillMail, AiFillFacebook } from "react-icons/ai";
import { BsFillPinMapFill } from "react-icons/bs";
import Logo from "../images/Flower Logo for website.png";
import Title from "./Title";
// import { Link } from "react-router-dom";

const HomePageFooter = ({
  scrollToSection,
  home,
  services,
  about,
  doctors,
  testimonials,
  appointment,
}) => {
  const navLinks = [
    {
      text: "Home",
      link: home,
    },
    {
      text: "Services",
      link: services,
    },
    {
      text: "About",
      link: about,
    },
    {
      text: "Doctors",
      link: doctors,
    },
    // {
    //   text: "Testimonials",
    //   link: testimonials,
    // },
    {
      text: "Appointment",
      link: appointment,
    },
  ];
  return (
    <Box bg={colours.blue} textColor={colours.light} w="100vw" maxW="100%">
      <Box
        maxW="1440px"
        mx="auto"
        py={{ lg: 16, md: 12, base: 8 }}
        px={{ lg: 20, md: 8, sm: 12, base: 4 }}
      >
        <Flex
          columnGap={{ md: 0, base: 8 }}
          rowGap={10}
          direction={{ md: "row", base: "column" }}
        >
          <Box w={{ lg: "45%", md: "60%", base: "100%" }} alignContent="end">
            <Image
              src={Logo}
              alt="Win Clinic Logo"
              width={{ md: "115px", base: "80px" }}
              height={{ md: "135px", base: "90px" }}
              mb={{ lg: 8, md: 6, base: 4 }}
            />
            <Text
              fontSize={{ xl: "14px", md: "12px", base: "14px" }}
              w="full"
              maxW={{ xl: "400px", md: "300px", base: "80%" }}
              letterSpacing={1}
              fontWeight="400"
            >
              Our objective is to have the best health care assessible at a
              neighbour distance. A health care that cures the cause, not
              patching the symptom, with an academic consultation with experts.
            </Text>
          </Box>
          <Box w="25%" display={{ lg: "block", base: "none" }}>
            <Box mb={6}>
              <Title
                text={"Menu"}
                fontSize="21px"
                fontWeight="bold"
                colour={colours.light}
              />
            </Box>
            {navLinks.map((navLink) => (
              <Box
                key={navLink.text}
                cursor="pointer"
                onClick={() => scrollToSection(navLink.link)}
                mb={2}
              >
                {navLink.text}
              </Box>
            ))}
          </Box>
          <Box w={{ lg: "30%", base: "95%" }}>
            <Box mb={{ md: 6, base: 4 }}>
              <Title
                text={"Contact Us"}
                fontSize="21px"
                fontWeight="bold"
                colour={colours.light}
              />
            </Box>
            <VStack align="start" spacing={4} mb={{ md: 8, base: 6 }}>
              <HStack spacing={4}>
                <AiFillPhone size={28} />
                <Box fontSize="14px" letterSpacing={1} fontWeight="400">
                  +959785069884
                </Box>
              </HStack>
              <HStack spacing={4}>
                <AiFillMail size={24} />
                <Box fontSize="14px" letterSpacing={1} fontWeight="400">
                  winspecialistclinic.hlaing@gmail.com
                </Box>
              </HStack>
              <HStack spacing={4}>
                <BsFillPinMapFill size={34} />
                <Box fontSize="14px" letterSpacing={1} fontWeight="400">
                  No (15), Marlarmyaing 2nd street, 16th Quarter, Hlaing
                  township, Yangon.
                </Box>
              </HStack>
            </VStack>
            <HStack spacing={6}>
              <Link
                isExternal
                href="https://facebook.com/ဝင်း-ဆေးခန်း-104589204615865/"
              >
                <AiFillFacebook size={48} />
              </Link>

              {/* <AiFillLinkedin size={48} />
              <AiOutlineInstagram size={48} /> */}
            </HStack>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default HomePageFooter;
