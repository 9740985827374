import { Flex } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return (
    <Flex
      marginTop="auto"
      width="100vw"
      height="50px"
      bgColor="#0090FE"
      justifyContent="center"
      alignItems="center"
      color="#fff"
    >
      Developed by @Yeyint Thway
    </Flex>
  );
};

export default Footer;
