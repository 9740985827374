import { Box, VStack, Image, Text } from "@chakra-ui/react";
import React from "react";
import Title from "./Title";
import { colours } from "../utils/colours";

const DoctorCard = ({ icon, iconAlt, title, text }) => {
  return (
    <Box
      bg={colours.light}
      height={{ lg: "280px", base: "240px" }}
      rounded="lg"
    >
      <Box textColor={colours.dark} px={{ lg: 4, base: 2 }} py={8}>
        <VStack spacing={4}>
          <Image
            src={icon}
            alt={iconAlt || "Icon"}
            width={{ xl: "80px", lg: "80px", base: "80px" }}
            height={{ xl: "80px", lg: "80px", base: "80px" }}
          />
          <Title
            text={title}
            fontSize={{ lg: "18px", base: "16px" }}
            fontWeight="bold"
            colour={colours.dark}
          />
          <Text
            fontSize={{ lg: "14px", base: "12px" }}
            fontWeight="600"
            textAlign="center"
            letterSpacing={1}
          >
            {text}
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};

export default DoctorCard;
