import { Box, Center, Text, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import Title from "./Title";
import One from "../images/01-Emergency-meetDr.png";
import Two from "../images/02-physician-meetDr.png";
import Three from "../images/03-Psy-meetDr.png";
import Four from "../images/04-Surg-meetDr.png";
import Five from "../images/05-OG-meetDr.png";
import Six from "../images/06-Uro-meetDr.png";
import Seven from "../images/07-ped-meetDr.png";
import Eight from "../images/08-GP-meetDr.png";
import Nine from "../images/09-dermato-meetDr.png";
import Ten from "../images/10-radio-meetDr.png";
import Eleven from "../images/11-ENT-meetDr.png";
import Twelve from "../images/12-ortho-meetDr.png";

import { colours } from "../utils/colours";
import DoctorCard from "./DoctorCard";

const doctorTypes = [
  {
    name: "Emergency Physician",
    image: One,
    description:
      "Accident and emergencies, trauma, injuries and other medical emergencies.",
  },
  {
    name: "Physician",
    image: Two,
    description:
      "Hypertension, Diabetes, and other medical diseases to rule out important medical causes.",
  },
  {
    name: "Psychiatrist",
    image: Three,
    description:
      "Psychiatric Problem, Mental illness, and Sleep disorders Counseling sections.",
  },
  {
    name: "Surgeon",
    image: Four,
    description:
      "General Diseases which need Surgical interventions and professional surgical management.",
  },
  {
    image: Five,
    name: "OG",
    description:
      "Pregnancy and pregnancy related diseases, Gynecological diseases and Family planning.",
  },
  {
    image: Six,
    name: "Urologist",
    description:
      "Kidney diseases, Stones, Prostate and other Urination problems.",
  },
  {
    image: Seven,
    name: "Pediatrician",
    description:
      "Very young kids who need special management with overall consideration of risks and side-effects",
  },
  {
    image: Eight,
    name: "General Practitioners",
    description:
      "General illness and other medical conditions which need a consultation as a first line management.",
  },
  {
    image: Nine,
    name: "Dermatologist",
    description:
      "Disease related with Skin, Hair, Nails with cosmetic problems to manage medically or surgically.",
  },
  {
    image: Ten,
    name: "Radiologist",
    description:
      "Diseases which need radiological impression viewing Ultrasound, X-Ray films, and CT scans.",
  },
  {
    image: Eleven,
    name: "ENT Surgeon",
    description:
      "Hearing Assessment, Disease related to Ears, Nose, Throat, Head and Neck region.",
  },
  {
    image: Twelve,
    name: "Orthopedic Surgeon",
    description:
      "Diseases related to Bones, Muscles, Joints and Nerves. Hands, Legs, Back and Spine",
  },
];

const DoctorsList = ({ doctors }) => {
  return (
    <Box
      bg={colours.blue}
      textColor={colours.light}
      w="100vw"
      maxW="100%"
      ref={doctors}
    >
      <Box
        maxW="1440px"
        mx="auto"
        py={{ lg: 16, md: 12, base: 8 }}
        // px={{ "2xl": 0, md: 16, sm: 6, base: 4 }}
        px={{ lg: 20, md: 8, sm: 12, base: 4 }}
      >
        <Center mb={4}>
          <Title
            text={"Meet Our Doctors"}
            fontSize="32px"
            fontWeight="bold"
            colour={colours.light}
          />
        </Center>
        <Center mb={12}>
          <Text
            maxW="800px"
            textAlign="center"
            fontSize="14px"
            letterSpacing={1}
            fontWeight="400"
          >
            You have full access to consult with clinicians who are expert in
            respective specialty managing corresponding disease.
          </Text>
        </Center>
        <SimpleGrid
          minChildWidth={{
            xl: "240px",
            md: "210px",
            base: "200px",
          }}
          spacingX={{ "2xl": 10, md: 8, base: 6 }}
          spacingY={12}
          px={{ lg: 0, md: 2, sm: 8, base: 10 }}
          py={4}
          mb={12}
        >
          {doctorTypes.map((item) => {
            return (
              <DoctorCard
                key={item.name}
                icon={item.image}
                iconAlt={item.name}
                title={item.name}
                text={item.description}
              />
            );
          })}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default DoctorsList;
