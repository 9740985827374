import {
  Input,
  InputRightElement,
  InputGroup,
  Button,
  Textarea,
  Select,
} from "@chakra-ui/react";
import React from "react";
import { colours } from "../utils/colours";
/* eslint-disable */

function InputComponent({
  placeholder,
  bgColor,
  color,
  type,
  id,
  value,
  onChange,
  onBlur,
  multiple,
  name,
  mb,
  rows,
  children,
}) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  switch (type) {
    case "text":
      return (
        <Input
          mb={mb}
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange ? (e) => onChange(e) : (e) => e}
          onBlur={onBlur}
          fontSize="16px"
          height="45px"
          placeholder={placeholder}
          border={bgColor || colours.background}
          _focus={{
            border: bgColor || colours.background,
            backgroundColor: bgColor || colours.background,
          }}
          _hover={{
            backgroundColor: bgColor || colours.background,
          }}
          variant="filled"
          bgColor={bgColor || colours.background}
          color={color || "#D2D2D2"}
          borderRadius="7px"
        />
      );
    case "select":
      return (
        <Select
          mb={mb}
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange ? (e) => onChange(e) : (e) => e}
          onBlur={onBlur}
          fontSize="16px"
          height="45px"
          placeholder={placeholder}
          border={bgColor || colours.background}
          _focus={{
            border: bgColor || colours.background,
            backgroundColor: bgColor || colours.background,
          }}
          _hover={{
            backgroundColor: bgColor || colours.background,
          }}
          variant="filled"
          bgColor={bgColor || colours.background}
          color={color || "#D2D2D2"}
          borderRadius="7px"
        >
          {children}
        </Select>
      );
    case "datetime-local":
      return (
        <Input
          mb={mb}
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange ? (e) => onChange(e) : (e) => e}
          onBlur={onBlur}
          fontSize="16px"
          height="45px"
          placeholder={placeholder}
          border={bgColor || colours.background}
          _focus={{
            border: bgColor || colours.background,
            backgroundColor: bgColor || colours.background,
          }}
          _hover={{
            backgroundColor: bgColor || colours.background,
          }}
          variant="filled"
          bgColor={bgColor || colours.background}
          color={color || "#D2D2D2"}
          borderRadius="7px"
        />
      );
    case "textarea":
      return (
        <Textarea
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          fontSize="16px"
          rows={rows || 10}
          placeholder={placeholder}
          border={bgColor || colours.background}
          _focus={{
            border: bgColor || colours.background,
            backgroundColor: bgColor || colours.background,
          }}
          _hover={{
            backgroundColor: bgColor || colours.background,
          }}
          variant="filled"
          bgColor={bgColor || colours.background}
          color={color || "#D2D2D2"}
          borderRadius="7px"
          size="lg"
        />
      );
    case "email":
      return (
        <Input
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          fontSize="16px"
          height="45px"
          placeholder={placeholder}
          border={colours.background}
          _focus={{
            border: colours.background,
            backgroundColor: colours.background,
          }}
          _hover={{
            backgroundColor: colours.background,
          }}
          variant="filled"
          bgColor={colours.background}
          color="#D2D2D2"
          borderRadius="7px"
        />
      );
    case "file":
      return (
        <Input
          id={id}
          name={name}
          type={type}
          multiple={multiple}
          onChange={onChange}
          textColor={colours.textColour}
          border="none"
          size="xs"
        />
      );
    case "password":
      return (
        <InputGroup>
          <Input
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            pr="4.5rem"
            fontSize="16px"
            height="45px"
            type={show ? "text" : "password"}
            placeholder={placeholder}
            bgColor={colours.background}
            border={colours.background}
            _focus={{
              border: colours.background,
              backgroundColor: colours.background,
            }}
            _hover={{
              backgroundColor: colours.background,
            }}
            variant="filled"
            color="#D2D2D2"
            borderRadius="7px"
          />
          <InputRightElement width="4.5rem" h="100%">
            <Button
              w="3rem"
              h="1.3rem"
              margin="auto"
              fontSize="10px"
              onClick={handleClick}
              bg={colours.main}
              _hover={{ backgroundColor: "#323f61" }}
              _focus={{ outline: "none" }}
              color="#D2D2D2"
              borderRadius="5px"
            >
              {show ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        </InputGroup>
      );
    default:
      return null;
  }
}

export default InputComponent;
