import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NormalRoute } from "./components/NormalRoute";
import { ProtectedRoute } from "./components/ProtectedRoute";
import AddNewVaccine from "./pages/AddNewVaccine";
import EditPage from "./pages/EditPage";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import CreatePatientForm from "./pages/PatientCreate";
import PatientList from "./pages/PatientList";
import VacationRecordPage from "./pages/VacationRecordPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="patient">
          <Route path=":patientId" element={<VacationRecordPage />} />
        </Route>
        <Route path="admin-user-panel">
          <Route
            path="login"
            element={
              <NormalRoute>
                <Login />
              </NormalRoute>
            }
          />
          <Route
            path="patient/list"
            element={
              <ProtectedRoute>
                <PatientList />
              </ProtectedRoute>
            }
          />
          <Route
            path="patient/edit/:id"
            element={
              <ProtectedRoute>
                <EditPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="patient/list/create"
            element={
              <ProtectedRoute>
                <CreatePatientForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="patient/list/edit/:id"
            element={
              <ProtectedRoute>
                <AddNewVaccine />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
