import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import logo from "../images/Win Logo Leave White Bg (2).jpg";
import clinicNameImage from "../images/1zODREMvvVRY7krAAusnHkZ9NSuUxe29mZHhqaZbOU4mLZ892Zw.gif";
import "./table.css";

const VaccineRecordCard = ({ qr, data }) => {
  const { name, dob, address, allergy, gender, NRC, vaccine } = data;

  return (
    <>
      {name &&
      dob &&
      address &&
      allergy &&
      gender &&
      NRC &&
      vaccine.length > 0 ? (
        <Flex flexDir="column" padding="20px">
          <Flex alignItems="flex-end">
            <Text mb="10px" fontSize="20px" fontWeight="extrabold">
              VACCINATION RECORD CARD
            </Text>
            <Image width="100px" src={logo} />
            <Image width="180px" src={clinicNameImage} />
            <Image src={qr} objectFit="contain" />
          </Flex>
          <Text>
            Please keep this record card which includes medical information
            about the vaccines you have received.
          </Text>
          <table
            style={{
              borderCollapse: "collapse",
              borderRadius: "10px",
              borderStyle: "hidden",
              boxShadow: "0 0 0 1px #666",
            }}
          >
            <tr align="center">
              <td
                style={{
                  backgroundColor: "#DBE5F1",
                  fontWeight: "bold",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
                colspan="6"
              >
                PATIENT INFORMATION
              </td>
            </tr>
            <tr align="center">
              <td style={{ fontWeight: "bold" }}>Name</td>
              <td>{data.name}</td>
              <td style={{ fontWeight: "bold" }}>Gender</td>
              <td>{data.gender}</td>
              <td style={{ fontWeight: "bold" }}>Date of Birth</td>
              <td>{data.dob}</td>
            </tr>
            <tr align="center">
              <td style={{ fontWeight: "bold" }}>NRC/Passport</td>
              <td colspan="3">{data.NRC}</td>
              <td style={{ fontWeight: "bold" }}>Allergy H/O</td>
              <td>{data.allergy}</td>
            </tr>
            <tr align="center">
              <td style={{ fontWeight: "bold", padding: "25px 0" }}>Address</td>
              <td colspan="5">{data.address}</td>
            </tr>
            <tr align="center">
              <td
                style={{
                  backgroundColor: "#DBE5F1",
                  fontWeight: "bold",
                  padding: "25px 0",
                }}
                align="center"
              >
                Vaccine Timing
              </td>
              <td
                align="center"
                style={{ backgroundColor: "#DBE5F1", fontWeight: "bold" }}
              >
                Type of Vaccine <br></br> Vaccine Name/ Lot No
              </td>
              <td
                align="center"
                style={{ backgroundColor: "#DBE5F1", fontWeight: "bold" }}
                colspan="2"
              >
                Date
              </td>
              <td
                align="center"
                style={{ backgroundColor: "#DBE5F1", fontWeight: "bold" }}
                colspan="2"
              >
                Healthcare Professional/ Clinic Site
              </td>
            </tr>

            {data.vaccine.map((item) => (
              <>
                <tr align="center">
                  <td align="center" rowspan="3" style={{ fontWeight: "bold" }}>
                    {item.vaccineTiming}
                  </td>
                  <td>{item.type}</td>
                  <td colspan="2">{item.date}</td>
                  <td colspan="2" rowspan="3">
                    {item.Professional}
                  </td>
                </tr>
                <tr align="center">
                  <td>{item.batch}</td>
                  <td colspan="2" rowspan="2">
                    yyyy/mm/dd
                  </td>
                </tr>
                <tr align="center">
                  <td>Exp: {item.exp}</td>
                </tr>
              </>
            ))}
          </table>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

export default VaccineRecordCard;
