import { Box, Center, SimpleGrid, Text } from "@chakra-ui/react";
import { colours } from "../utils/colours";
import One from "../images/01-Dr-services.png";
import Two from "../images/02-Med-check-services.png";
import Three from "../images/03-lab-services.png";
import Four from "../images/04-ECG-services.png";
import Five from "../images/05-Ultrasound-services.png";
import Six from "../images/06-CTG-services.png";
import Title from "./Title";
import ServiceCard from "./ServiceCard";
import React from "react";

const ServiceSection = ({ services }) => {
  return (
    <Box
      bg={colours.light}
      textColor={colours.dark}
      w="100vw"
      maxW="100%"
      ref={services}
    >
      <Box
        maxW="1440px"
        mx="auto"
        py={{ lg: 16, md: 12, base: 8 }}
        // px={{ "2xl": 0, md: 16, sm: 6, base: 4 }}
        px={{ lg: 20, md: 8, sm: 12, base: 4 }}
      >
        <Center mb={{ md: 4, base: 2 }}>
          <Title
            text={"Services We Provide"}
            fontSize={{ lg: "32px", base: "26px" }}
            fontWeight="bold"
          />
        </Center>
        <Center mb={{ md: 12, base: 8 }}>
          <Text
            maxW={{ lg: "800px", md: "640px", sm: "500px", base: "100%" }}
            textAlign="center"
            fontSize="14px"
            letterSpacing={1}
            fontWeight="600"
          >
            We are trying to maximize our range of services with the aid of
            modernized and qualified medical equipment producing test reports
            and records interpreted by well experienced clinicians.
          </Text>
        </Center>
        <SimpleGrid
          minChildWidth={{ lg: "340px", md: "300px", base: "260px" }}
          spacingX={8}
          spacingY={{ lg: 12, md: 8, base: 6 }}
          px={{ md: 0, sm: 8, base: 2 }}
        >
          <ServiceCard
            icon={One}
            iconAlt="Doctor Icon"
            title={"Qualified Doctors"}
            text={"Consultation with Qualified Medical Doctors"}
          />
          <ServiceCard
            icon={Two}
            iconAlt="Doctor Icon"
            title={"Qualified Doctors"}
            text={
              "General Medical Check-Up for Well-being, Occupation and School related assessments"
            }
          />
          <ServiceCard
            icon={Three}
            iconAlt="Doctor Icon"
            title={"Qualified Doctors"}
            text={
              "Laboratory Tests for Blood, Sputum, Urine and other specimens"
            }
          />
          <ServiceCard
            icon={Four}
            iconAlt="Doctor Icon"
            title={"Qualified Doctors"}
            text={
              "ECG Tests for Cardiac assessment and clinician consultations"
            }
          />
          <ServiceCard
            icon={Five}
            iconAlt="Doctor Icon"
            title={"Qualified Doctors"}
            text={
              "Ultrasound for Diagnosis of Disease in Abdomen. Pelvis, Uterus & Ovary, Thyroid, Breast and Chest"
            }
          />
          <ServiceCard
            icon={Six}
            iconAlt="Doctor Icon"
            title={"Qualified Doctors"}
            text={
              "CTG Tests for High Risk Pregnancy Fetal well-being assessment"
            }
          />
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default ServiceSection;
