import {
  Box,
  Center,
  HStack,
  Stack,
  Text,
  VStack,
  Radio,
  RadioGroup,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";
import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import { colours } from "../utils/colours";
import InputComponent from "./Input";
import Title from "./Title";

const AppointmentForm = ({ appointment }) => {
  const form = useRef();
  const toast = useToast();
  const [formData, setFormData] = useState({
    appointment_date: "",
    doctor: "",
    name: "",
    phone_no: "",
    age: "",
    gender: "",
    address: "",
    description: "",
  });

  const handleChange = (e) => {
    if (typeof e == "string") {
      setFormData((prev) => {
        return { ...prev, gender: e };
      });
    } else {
      const name = e.target.name;
      const value = e.target.value;
      setFormData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData.appointment_date &&
      formData.doctor &&
      formData.name &&
      formData.phone_no &&
      formData.age &&
      formData.gender &&
      formData.address &&
      formData.description
    ) {
      emailjs
        .sendForm(
          "service_ij5m4rv",
          "template_sez32if",
          form.current,
          "JYveTFf_V7fpINtgt"
        )
        .then(
          (result) => {
            document.getElementById("appointment-form").reset();
            setFormData({
              appointment_date: "",
              doctor: "",
              name: "",
              phone_no: "",
              age: "",
              gender: "",
              address: "",
              description: "",
            });
            toast({
              position: "bottom-right",
              title:
                "Your message was successfully sent to Win Clinic. We will contact you later",
              status: "success",
              duration: 6000,
              isClosable: true,
            });
          },
          (error) => {
            toast({
              position: "bottom-right",
              title: "Error In Sending Message. Please Try Again Later",
              status: "warning",
              duration: 6000,
              isClosable: true,
            });
          }
        );
    } else {
      toast({
        position: "bottom-right",
        title: "Please fill all information",
        status: "warning",
        duration: 6000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg={colours.light}
      textColor={colours.dark}
      w="100vw"
      maxW="100%"
      ref={appointment}
    >
      <Box
        maxW="1440px"
        mx="auto"
        py={{ lg: 16, md: 12, base: 8 }}
        // px={{ "2xl": 0, md: 16, sm: 6, base: 4 }}
        px={{ lg: 20, md: 8, sm: 12, base: 4 }}
      >
        <Center mb={{ md: 4, base: 2 }}>
          <Title
            text={"APPOINTMENT"}
            fontSize={{ lg: "32px", base: "26px" }}
            fontWeight="bold"
          />
        </Center>
        <Center mb={{ md: 12, base: 8 }}>
          <Text
            maxW={{ lg: "800px", md: "640px", sm: "500px", base: "100%" }}
            textAlign="center"
            fontSize="14px"
            letterSpacing={1}
            fontWeight="600"
          >
            Fill the form and our team will get back to you within 24 hours.
          </Text>
        </Center>
        <Box px={{ lg: 20, base: 0 }}>
          <Box
            id="appointment-form"
            as="form"
            ref={form}
            onSubmit={handleSubmit}
            bg={colours.blue}
            textColor={colours.light}
            w="100%"
            h="fit-content"
            px={{ xl: "120px", md: 16, sm: 8, base: 6 }}
            py={{ xl: 20, base: 12 }}
          >
            <VStack w="full" spacing={{ lg: 10, sm: 8, base: 4 }}>
              <Stack
                w="full"
                direction={{ md: "row", base: "column" }}
                spacing={6}
              >
                <Flex w="full" direction="column">
                  <Text
                    as={"label"}
                    w="fit-content"
                    htmlFor="appointment_date"
                    fontSize="14px"
                    fontWeight="600"
                    letterSpacing={1}
                    mb={1}
                  >
                    Appointment Date
                  </Text>
                  <InputComponent
                    type="datetime-local"
                    id="appointment_date"
                    name="appointment_date"
                    bgColor={colours.light}
                    color={colours.dark}
                    onChange={handleChange}
                  />
                </Flex>
                <Flex w="full" direction="column">
                  <Text
                    as={"label"}
                    w="fit-content"
                    htmlFor="doctor"
                    fontSize="14px"
                    fontWeight="600"
                    letterSpacing={1}
                    mb={1}
                  >
                    Doctors
                  </Text>
                  <InputComponent
                    type="select"
                    id="doctor"
                    name="doctor"
                    placeholder="Select Doctors"
                    bgColor={colours.light}
                    color={colours.dark}
                    onChange={handleChange}
                  >
                    <option value="Dr. Thet Nyi Nyi Lwin M.B.B.S(Ygn)">
                      Dr. Thet Nyi Nyi Lwin &lt; M.B.B.S(Ygn) &gt;
                    </option>
                    <option value="Dr. Hnin Aye Hlaing M.B.B.S(Ygn)">
                      Dr. Hnin Aye Hlaing &lt; M.B.B.S(Ygn) &gt;
                    </option>
                    <option value="Dr. Aung Pyone M.B.B.S(Ygn)">
                      Dr. Aung Pyone &lt; M.B.B.S(Ygn) &gt;
                    </option>
                    <option value="Dr. Aung Myat M.B.B.S(Ygn)">
                      Dr. Aung Myat &lt; M.B.B.S(Ygn) &gt;
                    </option>
                    <option value="Dr. Myo Thient Theint Thu M.B.B.S(Mgy)">
                      Dr. Myo Thient Theint Thu &lt; M.B.B.S(Mgy) &gt;
                    </option>
                  </InputComponent>
                </Flex>
              </Stack>
              <Stack
                w="full"
                direction={{ md: "row", base: "column" }}
                spacing={6}
              >
                <Flex w="full" direction="column">
                  <Text
                    as={"label"}
                    w="fit-content"
                    htmlFor="name"
                    fontSize="14px"
                    fontWeight="600"
                    letterSpacing={1}
                    mb={1}
                  >
                    Your Name
                  </Text>
                  <InputComponent
                    type="text"
                    id="name"
                    name="name"
                    bgColor={colours.light}
                    color={colours.dark}
                    onChange={handleChange}
                  />
                </Flex>
                <Flex w="full" direction="column">
                  <Text
                    as={"label"}
                    w="fit-content"
                    htmlFor="phone_no"
                    fontSize="14px"
                    fontWeight="600"
                    letterSpacing={1}
                    mb={1}
                  >
                    Phone Number
                  </Text>
                  <InputComponent
                    type="text"
                    id="phone_no"
                    name="phone_no"
                    bgColor={colours.light}
                    color={colours.dark}
                    onChange={handleChange}
                  />
                </Flex>
                <Flex w={{ md: "40%", base: "100%" }} direction="column">
                  <Text
                    as={"label"}
                    w="fit-content"
                    htmlFor="age"
                    fontSize="14px"
                    fontWeight="600"
                    letterSpacing={1}
                    mb={1}
                  >
                    Age
                  </Text>
                  <InputComponent
                    type="text"
                    id="age"
                    name="age"
                    bgColor={colours.light}
                    color={colours.dark}
                    onChange={handleChange}
                  />
                </Flex>
              </Stack>
              <Box alignSelf="start">
                <RadioGroup onChange={handleChange} id="gender" name="gender">
                  <HStack align="start" spacing={6}>
                    <Radio value="male" size={{ sm: "md", base: "sm" }}>
                      Male
                    </Radio>
                    <Radio value="female" size={{ sm: "md", base: "sm" }}>
                      Female
                    </Radio>
                    <Radio value="ratherNotSay" size={{ sm: "md", base: "sm" }}>
                      Rather Not Say
                    </Radio>
                  </HStack>
                </RadioGroup>
              </Box>
              <Box w="full">
                <Flex w="full" direction="column">
                  <Text
                    as={"label"}
                    w="fit-content"
                    htmlFor="address"
                    fontSize="14px"
                    fontWeight="600"
                    letterSpacing={1}
                    mb={1}
                  >
                    Address
                  </Text>
                  <InputComponent
                    type="text"
                    id="address"
                    name="address"
                    bgColor={colours.light}
                    color={colours.dark}
                    onChange={handleChange}
                  />
                </Flex>
              </Box>
              <Box w="full">
                <Flex w="full" direction="column">
                  <Text
                    as={"label"}
                    w="fit-content"
                    htmlFor="description"
                    fontSize="14px"
                    fontWeight="600"
                    letterSpacing={1}
                    mb={1}
                  >
                    Description
                  </Text>
                  <InputComponent
                    type="textarea"
                    id="description"
                    name="description"
                    rows={6}
                    bgColor={colours.light}
                    color={colours.dark}
                    onChange={handleChange}
                  />
                </Flex>
              </Box>
              <Box alignSelf="end" pt={{ sm: 0, base: 2 }}>
                <Button
                  variant="solid"
                  bg={colours.light}
                  textColor={colours.dark}
                  _focus={{ outline: "none" }}
                  _hover={{ backgroundColor: "#F2F2F2" }}
                  type="submit"
                  fontSize="14px"
                  fontWeight="bold"
                  py={{ lg: 6, base: 4 }}
                  px={10}
                >
                  Send
                </Button>
              </Box>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AppointmentForm;
