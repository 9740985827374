import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import QRCode from "qrcode";
import { useReactToPrint } from "react-to-print";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
} from "@chakra-ui/react";
import { colours } from "../utils/colours";
import ButtonComponent from "../components/Button";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import VaccineRecordCard from "../components/VaccineRecordCard";
import { backendURL, frontendURL } from "../utils/constants";
import { token } from "../utils/token";

function CreatePatientForm() {
  const componentRef = useRef(null);
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [allergy, setAllergy] = useState("");
  const [gender, setGender] = useState("");
  const [NRC, setNRC] = useState("");
  const [vaccineTiming, setTiming] = useState("");
  const [type, setType] = useState("");
  const [batch, setBatch] = useState("");
  const [exp, setExp] = useState("");
  const [date, setDate] = useState("");
  const [Professional, setProfessional] = useState("");
  const [src, setSrc] = useState("");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const print = () => {
    handlePrint();
    setName("");
    setDob("");
    setAddress("");
    setAllergy("");
    setGender("");
    setNRC("");
    setTiming("");
    setType("");
    setBatch("");
    setExp("");
    setDate("");
    setProfessional("");
  };

  useEffect(() => {
    src && print();
  }, [src]);

  // With async/await
  const generateQR = async (text) => {
    try {
      setSrc(await QRCode.toDataURL(text));
    } catch (err) {
      console.error(err);
    }
  };

  async function createSeasonHandler() {
    if (
      name &&
      dob &&
      address &&
      allergy &&
      gender &&
      NRC &&
      vaccineTiming &&
      type &&
      batch &&
      exp &&
      date &&
      Professional
    ) {
      const { data } = await axios.post(
        `${backendURL}patients`,
        {
          name,
          dob,
          address,
          allergy,
          gender,
          NRC,
          vaccine: [
            {
              vaccineTiming,
              type,
              batch,
              exp,
              date,
              Professional,
            },
          ],
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      generateQR(`${frontendURL}patient/${data._id}`);
    }
  }

  return (
    <Flex bg={colours.light} flexDir="column" minH="100vh">
      <Navbar />
      <Flex display="none">
        <Flex ref={componentRef} flexDir="column">
          <VaccineRecordCard
            qr={src}
            data={{
              name,
              dob,
              address,
              allergy,
              gender,
              NRC,
              vaccine: [
                {
                  vaccineTiming,
                  type,
                  batch,
                  exp,
                  date,
                  Professional,
                },
              ],
            }}
          />
        </Flex>
      </Flex>
      <Box p={7} py={10}>
        <Flex gridGap="15px" my="10px">
          <FormControl>
            <FormLabel as="legend">Name</FormLabel>
            <Input
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Date of birth</FormLabel>
            <Input
              type="date"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Allergy</FormLabel>
            <Input
              placeholder="Allergy"
              value={allergy}
              onChange={(e) => setAllergy(e.target.value)}
            />
          </FormControl>
        </Flex>
        <FormControl>
          <FormLabel as="legend">Gender</FormLabel>
          <RadioGroup
            defaultValue="Itachi"
            value={gender}
            onChange={(e) => setGender(e)}
          >
            <HStack spacing="24px">
              <Radio value="Male">Male</Radio>
              <Radio value="Female">Female</Radio>
              <Radio value="Other">Other</Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <Flex gridGap="15px" my="10px">
          <FormControl>
            <FormLabel as="legend">NRC/Passport</FormLabel>
            <Input
              placeholder="NRC/Passport"
              value={NRC}
              onChange={(e) => setNRC(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Address</FormLabel>
            <Input
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Vaccine Timing</FormLabel>
            <Input
              placeholder="Vaccine Timing"
              value={vaccineTiming}
              onChange={(e) => setTiming(e.target.value)}
            />
          </FormControl>
        </Flex>
        <Flex gridGap="15px" my="10px">
          <FormControl>
            <FormLabel as="legend">Type of vaccine</FormLabel>
            <Input
              placeholder="Type of vaccine"
              value={type}
              onChange={(e) => setType(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Batch No</FormLabel>
            <Input
              placeholder="Batch No"
              value={batch}
              onChange={(e) => setBatch(e.target.value)}
            />
          </FormControl>
        </Flex>
        <Flex gridGap="15px" my="10px">
          <FormControl>
            <FormLabel as="legend">Expiry Date</FormLabel>
            <Input
              placeholder="Expiry Date"
              type="date"
              value={exp}
              onChange={(e) => setExp(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Date</FormLabel>
            <Input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">
              Healthcare Professional/ Clinic Site
            </FormLabel>
            <Select
              value={Professional}
              onChange={(e) => setProfessional(e.target.value)}
              placeholder="Select Healthcare Professional/ Clinic Site"
            >
              <option> Dr. Thet Nyi Nyi Lwin M.B.,B.S (Ygn)</option>
              <option> Dr. Hnin Aye Hlaing M.B.,B.S (Ygn)</option>
              <option> Dr. Aung Phone M.B.,B.S (Ygn)</option>
              <option> Dr. Aung Myat M.B.,B.S (Ygn)</option>
              <option> Dr. Myo Theint Theint Thu M.B.,B.S (Mgy)</option>
            </Select>
          </FormControl>
        </Flex>
        <Flex justifyContent="flex-end">
          <ButtonComponent
            text="Create and Print"
            size="md"
            onClick={createSeasonHandler}
          />
        </Flex>
      </Box>
      <Footer />
    </Flex>
  );
}

export default CreatePatientForm;
