import { Box, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { colours } from "../utils/colours";

const ServiceCard = ({ icon, iconAlt, title, text }) => {
  return (
    <Box
      bg={colours.blue}
      height={{
        xl: "250px",
        lg: "250px",
        md: "250px",
        sm: "auto",
        base: "auto",
      }}
      rounded="lg"
    >
      <Box textColor={colours.light} px={{ xl: 20, lg: 16, base: 8 }} py={8}>
        <VStack spacing={4}>
          <Image
            src={icon}
            alt={iconAlt || "Icon"}
            objectFit="contain"
            width={{ xl: 20, lg: 16, md: 12, base: 10 }}
            height={{ xl: 20, lg: 16, md: 12, base: 10 }}
          />
          <Text
            fontSize="14px"
            fontWeight="400"
            textAlign="center"
            letterSpacing={1}
          >
            {text}
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};

export default ServiceCard;
