import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import QRCode from "qrcode";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { colours } from "../utils/colours";
import ButtonComponent from "../components/Button";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import VaccineRecordCard from "../components/VaccineRecordCard";
import { backendURL, frontendURL } from "../utils/constants";
import { token } from "../utils/token";

function AddNewVaccine() {
  const params = useParams();
  const componentRef = useRef(null);
  const [res, setRes] = useState({});
  const [vaccineTiming, setTiming] = useState("");
  const [type, setType] = useState("");
  const [batch, setBatch] = useState("");
  const [exp, setExp] = useState("");
  const [date, setDate] = useState("");
  const [Professional, setProfessional] = useState("");
  const [src, setSrc] = useState("");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const print = () => {
    handlePrint();
    setSrc("");
    setTiming("");
    setType("");
    setBatch("");
    setExp("");
    setDate("");
    setProfessional("");
  };

  const fetchPatientData = async () => {
    const { data } = await axios.get(`${backendURL}patients/${params.id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    setRes(data);
  };

  useEffect(() => {
    fetchPatientData();
  }, []);

  useEffect(() => {
    src && print();
  }, [src]);

  // With async/await
  const generateQR = async (text) => {
    try {
      setSrc(await QRCode.toDataURL(text));
    } catch (err) {
      console.error(err);
    }
  };

  async function createSeasonHandler() {
    if (vaccineTiming && type && batch && exp && date && Professional) {
      const { data } = await axios.put(
        `${backendURL}patients/${params.id}`,
        {
          newVaccine: {
            vaccineTiming,
            type,
            batch,
            exp,
            date,
            Professional,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      await generateQR(`${frontendURL}patient/${data._id}`);

      //   src && handlePrint();
    }
  }

  return (
    <Flex bg={colours.light} flexDir="column" minH="100vh">
      <Navbar />
      <Flex display="none">
        <Flex ref={componentRef} flexDir="column">
          {res?.vaccine && (
            <VaccineRecordCard
              qr={src}
              data={
                vaccineTiming && type && batch && exp && date && Professional
                  ? {
                      name: res.name,
                      dob: res.dob,
                      address: res.address,
                      allergy: res.allergy,
                      gender: res.gender,
                      NRC: res.NRC,
                      vaccine: [
                        ...res.vaccine,
                        {
                          vaccineTiming,
                          type,
                          batch,
                          exp,
                          date,
                          Professional,
                        },
                      ],
                    }
                  : {
                      name: res.name,
                      dob: res.dob,
                      address: res.address,
                      allergy: res.allergy,
                      gender: res.gender,
                      NRC: res.NRC,
                      vaccine: res.vaccine,
                    }
              }
            />
          )}
        </Flex>
      </Flex>
      <Box p={7} py={10}>
        <Flex gridGap="15px" my="10px">
          <FormControl>
            <FormLabel as="legend">Vaccine Timing</FormLabel>
            <Input
              placeholder="Vaccine Timing"
              value={vaccineTiming}
              onChange={(e) => setTiming(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Type of vaccine</FormLabel>
            <Input
              placeholder="Type of vaccine"
              value={type}
              onChange={(e) => setType(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Batch No</FormLabel>
            <Input
              placeholder="Batch No"
              value={batch}
              onChange={(e) => setBatch(e.target.value)}
            />
          </FormControl>
        </Flex>
        <Flex gridGap="15px" my="10px">
          <FormControl>
            <FormLabel as="legend">Expiry Date</FormLabel>
            <Input
              placeholder="Expiry Date"
              type="date"
              value={exp}
              onChange={(e) => setExp(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">Date</FormLabel>
            <Input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel as="legend">
              Healthcare Professional/ Clinic Site
            </FormLabel>
            <Select
              value={Professional}
              onChange={(e) => setProfessional(e.target.value)}
              placeholder="Select Healthcare Professional/ Clinic Site"
            >
              <option> Dr. Thet Nyi Nyi Lwin M.B.,B.S (Ygn)</option>
              <option> Dr. Hnin Aye Hlaing M.B.,B.S (Ygn)</option>
              <option> Dr. Aung Phone M.B.,B.S (Ygn)</option>
              <option> Dr. Aung Myat M.B.,B.S (Ygn)</option>
              <option> Dr. Myo Theint Theint Thu M.B.,B.S (Mgy)</option>
            </Select>
          </FormControl>
        </Flex>
        <Flex justifyContent="flex-end">
          <ButtonComponent
            text="Update and Print"
            size="md"
            onClick={createSeasonHandler}
          />
        </Flex>
      </Box>
      <Footer />
    </Flex>
  );
}

export default AddNewVaccine;
