import React from "react";
import {
  Box,
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Image,
} from "@chakra-ui/react";
import { colours } from "../utils/colours";
import WhyChooseWin from "../images/why-choose-win.png";
import Title from "./Title";

const About = ({ about }) => {
  return (
    <Box
      bg={colours.light}
      textColor={colours.dark}
      w="100vw"
      maxW="100%"
      ref={about}
    >
      <Box
        maxW="1440px"
        mx="auto"
        py={{ lg: 16, md: 12, base: 8 }}
        // px={{ "2xl": 0, md: 16, sm: 6, base: 4 }}
        px={{ lg: 20, md: 8, sm: 12, base: 4 }}
      >
        <Flex alignItems="center" direction={{ md: "row", base: "column" }}>
          <Image
            w="50%"
            display={{ lg: "block", base: "none" }}
            src={WhyChooseWin}
            alt="Why Choose Win Specialist Clinic"
            objectFit="cover"
          />
          <Box
            w={{ lg: "50%", sm: "80%", base: "90%" }}
            pl={{ xl: 16, lg: 8, md: 16, base: 0 }}
          >
            <Box mb={{ xl: 4, lg: 2, base: 4 }}>
              <Title
                text={"Why choose"}
                fontSize={{ xl: "36px", lg: "28px", md: "32px", base: "28px" }}
                fontWeight="regular"
                colour={colours.dark}
              />
              <Title
                text={"Win Specialist Cinic?"}
                fontSize={{ xl: "36px", lg: "28px", md: "32px", base: "28px" }}
                fontWeight="bold"
                colour={colours.blue}
              />
            </Box>
            <Text
              fontSize="14px"
              fontWeight="600"
              letterSpacing={1}
              mb={{ xl: 4, lg: 2, base: 4 }}
            >
              Patients choose Win Specialist Clinic for achieving the following
              requirements in which a patient usually seeks for during a doctor
              appointment.
            </Text>
            <UnorderedList
              spacing={{ xl: 1, lg: 0.6, base: 1 }}
              fontSize="14px"
              fontWeight="600"
              letterSpacing={1.2}
            >
              <ListItem>Fair price of health care services</ListItem>
              <ListItem>
                Based in community having easy access within short travel time
              </ListItem>
              <ListItem>
                Available extended services in one place., Laboratory, ECG,
                Ultrasound, CTG services
              </ListItem>
              <ListItem>
                Academic prescription with up-to-date treatments from variable
                specialist doctors
              </ListItem>
              <ListItem>
                Emergency health care services which save lives with right
                decisions in correct timing
              </ListItem>
              <ListItem>
                Health Education shared on eventual outbreak incidences
              </ListItem>
            </UnorderedList>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default About;
