import { Button } from "@chakra-ui/react";
import React from "react";
import { colours } from "../utils/colours";

function ButtonComponent({
  bg,
  text,
  onClick,
  disabled,
  size,
  type,
  isLoading,
}) {
  return (
    <Button
      paddingX={(size === "md" && 6) || 2}
      backgroundColor={bg || colours.secondary}
      color={colours.light}
      _focus={{ outline: "none" }}
      _hover={{ backgroundColor: bg || colours.secondary }}
      onClick={onClick}
      disabled={disabled}
      size={size}
      type={type}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
}

export default ButtonComponent;
