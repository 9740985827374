import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { AiFillPhone, AiFillMail } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import logo from "../images/letterhead top header (1).jpg";
import ButtonComponent from "./Button";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <Flex flexDir="column">
      <Flex
        align="center"
        justifyContent="space-between"
        flexDir={{
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column",
          base: "column",
        }}
      >
        <Image src={logo} width="500px" />
        <Flex
          flexDir="column"
          gridGap="10px"
          mt={{
            xl: "60px",
            lg: "60px",
            md: "60px",
            sm: "20px",
            base: "20px",
          }}
          width="100vw"
          justifyContent="flex-end"
          mr="40px"
        >
          <Flex align="center" justifyContent="flex-end">
            Hotline{" "}
            <AiFillPhone style={{ marginRight: "10px", marginLeft: "10px" }} />{" "}
            +959785069884
          </Flex>
          <Flex align="center" justifyContent="flex-end">
            Email{" "}
            <AiFillMail style={{ marginRight: "10px", marginLeft: "21px" }} />{" "}
            winspecialistclinic.hlaing@gmail.com
          </Flex>
          <Flex align="center" justifyContent="flex-end">
            {localStorage.getItem("winToken") && (
              <ButtonComponent
                bg="#111"
                text="Logout"
                size="md"
                onClick={() => {
                  localStorage.removeItem("winToken");
                  navigate("/admin-user-panel/login");  
                }}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Box height="30px" width="100vw" bgColor="#0090FE" mt="10px" />
    </Flex>
  );
};

export default Navbar;
