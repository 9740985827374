import { Box, Button, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { colours } from "../utils/colours";

function Paginator({ totalPages, currentPage, setPage }) {
  return (
    <Box
      display="flex"
      fontSize="14px"
      mt="3"
      alignItems="center"
      textColor={colours.textColour}
    >
      <Text>
        Page {currentPage} of {totalPages}
      </Text>
      <Spacer />
      <Flex>
        <Button
          px="4"
          h="28px"
          fontSize="14px"
          fontWeight="semibold"
          color={colours.background}
          backgroundColor={colours.secondary}
          mr="10px"
          _hover={{ backgroundColor: "#C3A402" }}
          onClick={() => currentPage !== 1 && setPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          prev
        </Button>
        <Button
          px="4"
          h="28px"
          fontSize="14px"
          fontWeight="semibold"
          color={colours.background}
          backgroundColor={colours.secondary}
          _hover={{ backgroundColor: "#C3A402" }}
          disabled={currentPage === totalPages}
          onClick={() => currentPage !== totalPages && setPage(currentPage + 1)}
        >
          next
        </Button>
      </Flex>
      {/* <Divider /> */}
    </Box>
  );
}

export default Paginator;
